/* dashboard section css start */
.d-widget {
  @include d-flex;
  align-items: center;
  padding: rem(20px);
  @extend .glass--bg;
  border: 2px solid rgba($base-color, 0.15);
  position: relative;
  z-index: 1;
  overflow: hidden;
  $icon: 95px;
  @extend %transition;
  @include media(380px) {
    justify-content: center; }
  &:hover {
    border-color: $base-color;
    .d-widget__amount {
      &::after {
        background-color: $base-color; } } }
  &__icon {
    width: $icon;
    height: $icon;
    background-color: $base-color-two;
    @include border-radius(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: rem(52px);
      color: $base-color;
      text-shadow: 0 0 15px rgba($base-color, 0.5); } }
  &__content {
    padding-left: rem(20px);
    width: calc(100% - #{$icon});
    @include media(380px) {
      padding-left: 0;
      width: 100%;
      text-align: center;
      margin-top: rem(25px); } }
  &__amount {
    font-size: rem(32px);
    padding-bottom: rem(6px);
    margin-bottom: rem(5px);
    position: relative;
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: rem(-20px);
      height: 1px;
      width: calc(100% + #{rem(40px)});
      background-color: rgba($base-color, 0.15);
      z-index: -1;
      @extend %transition; } } }
/* dashboard section css end */

/* deposit section css start */
.deposit-card {
  padding: rem(30px);
  background-color: rgba(#ffffff, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid rgba($base-color, 0.25);
  @extend  %transition;
  &:hover {
    border-color: $base-color;
    box-shadow: 0 5px 20px 1px rgba($base-color, 0.35);
    @include transform(translateY(-5px)); }
  &__title {
    font-size: rem(24px);
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#ffffff, 0.15); } }
/* deposit section css end */


/* profile section css start */
.profile-form {
  padding: 0 rem(50px) rem(50px) rem(50px);
  background-color: rgba(#ffffff, 0.10);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-top: 100px;
  box-shadow: 0 5px 15px 1px rgba(#000000, 0.15);
  @include media(575px) {
    padding: 0 rem(30px) rem(30px) rem(30px); } }
.profile-thumb {
  position: relative;
  width: rem(180px);
  height: rem(180px);
  @include border-radius(15px);
  display: inline-flex;
  margin-top: -100px;
  .profilePicPreview {
    width: rem(180px);
    height: rem(180px);
    @include border-radius(15px);
    display: block;
    border: 3px solid #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center; }
  .profilePicUpload {
    font-size: 0;
    opacity: 0; }
  .avatar-edit {
    position: absolute;
    right: 35%;
    bottom: -30px;
    input {
      width: 0; }
    label {
      width: 45px;
      height: 45px;
      background-color: $base-color;
      border-radius: 50%;
      text-align: center;
      line-height: 45px;
      border: 2px solid #ffffff;
      font-size: 18px;
      cursor: pointer;
      color: #000000; } } }
/* profile section css end */


/* deposit preview section css start */
.deposit-preview-card {
  padding: 30px;
  background-color: rgba(#ffffff, 0.10);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  text-align: center;
  border: 2px solid rgba($base-color, 1);
  box-shadow: 0 5px 15px 1px rgba($base-color, 0.25);
  .title {
    font-size: rem(28px); }
  .deposit-preview-list {
    li {
      padding: rem(10px) 0;
      border-bottom: 1px solid rgba(#ffffff, 0.15);
      &:last-child {
        border-bottom: none; } } } }
/* deposit preview section css end */

/* authentication section css start */
.qr-code-wrapper {
  padding: rem(20px);
  background-color: rgba($base-color-two, 0.45); }
.qr-code {
  padding: rem(10px) rem(20px);
  background-color: rgba($base-color-two, 0.45);
  @include border-radius(5px); }
.qr-code-copy-form {
  @include d-flex;
  input[type="text"] {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: rem(20px);
    width: calc(100% - 75px);
    height: 40px;
    @include media(440px) {
      font-size: rem(16px); }
    @include media(360px) {
      font-size: rem(14px); } }
  input[type="submit"] {
    width: 75px;
    background-color: $base-color;
    border: none;
    @include border-radius(5px); } }
.qr-code-form {
  position: relative;
  .form-control {
    height: rem(65px);
    padding-right: rem(95px); }
  &__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 75px;
    height: calc(100% - 20px); } }
/* authentication section css end */

/* support section css start */
.custom--file-upload {
  position: relative;
  line-height: rem(32px);
  &::before {
    position: absolute;
    content: 'Choose File';
    color: #000000;
    top: 0;
    left: 0;
    width: 125px;
    height: 100%;
    background-color: $base-color;
    padding: rem(10px) rem(20px); } }

.single-reply {
  @include d-flex;
  align-items: center;
  padding: rem(30px);
  background-color: rgba($base-color-two, 0.65);
  .left {
    width: 20%;
    @include media(767px) {
      width: 100%; } }
  .right {
    width: 80%;
    border-left: 1px solid rgba(#ffffff, 0.15);
    padding-left: rem(20px);
    @include media(767px) {
      width: 100%;
      padding-left: 0;
      border-left: none;
      margin-top: rem(20px); } } }
.single-reply + .single-reply {
  margin-top: 20px; }
/* support section css end */
