@import "./_reset";
/* global css strat */
@import "./_color";
@import "./_bg";
// margin global css
.mb-30 {
  margin-bottom: 30px; }
.mb-none-30 {
  margin-bottom: -30px; }

// padding global css
.pt-100 {
  padding-top: 100px; }
.pb-100 {
  padding-bottom: 100px; }
.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
.glass--bg {
  background-color: rgba($base-color, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); }
.glass--bg-two {
  background-color: rgba($base-color-two, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); }
.glass--bg-three {
  background-color: rgba(#ffffff, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); }
.overlay--one {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.45;
    z-index: -1; } }

.slick-arrow {
  cursor: pointer; }

.section-header {
  margin-bottom: rem(65px); }
.section-title {
  font-size: rem(52px);
  @media (max-width: 1199px) {
    font-size: rem(48px); }
  @media (max-width: 991px) {
    font-size: rem(36px); } }
a.text-white {
  &:hover {
    color: $base-color !important; } }
.custom--accordion {
  .accordion-item + .accordion-item {
    margin-top: rem(20px); }
  .accordion-item {
    border: 1px solid rgba($base-color, 0.5);
    @include border-radius(5px);
    &:first-child {
      .accordion-button {
        border-top: none; } }
    &:last-child {
      .accordion-button {
        border-bottom: none; } } }
  .accordion-button {
    color: #ffffff;
    padding: rem(20px) rem(25px);
    background-color: rgba($base-color, 0.15);
    font-size: rem(18px);
    position: relative;
    text-align: left;
    &::after {
      position: absolute;
      top: rem(20px);
      right: rem(13px);
      font-size: rem(17px);
      content: '\f107';
      font-family: 'Line Awesome Free';
      font-weight: 900;
      background-image: none;
      color: #ffffff; }
    &:not(.collapsed) {
      background-color: $base-color;
      color: #000;
      &::after {
        color: #000; } }
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: transparent; } }
  .accordion-body {
    padding: rem(20px) rem(25px); } }
.page-breadcrumb {
  @include d-flex;
  margin-top: rem(15px);
  li {
    color: rgba(#ffffff, 0.8);
    text-transform: capitalize;
    &::after {
      content: '-';
      color: #ffffff;
      margin: 0 rem(5px); }
    &:first-child {
      &::before {
        content: "\f015";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $base-color;
        margin-right: rem(6px); } }
    &:last-child {
      &::after {
        display: none; } }
    a {
      color: #ffffff;
      text-transform: capitalize;
      &:hover {
        color: $base-color; } } } }

.scroll-to-top {
  height: rem(60px);
  width: rem(60px);
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  background-color: $base-color;
  line-height: rem(77px);
  box-shadow: 0 5px 15px 0 rgba(#000000, 0.25);
  .scroll-icon {
    font-size: rem(31px);
    color: #ffffff;
    display: inline-block;
    i {
      @include transform(rotate(-45deg)); } } }
%transition {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
.progress-bar {
  background-color: $base-color; }
.video-btn {
  position: relative;
  width: rem(105px);
  height: rem(105px);
  color: #ffffff;
  @include border-radius(50%);
  display: inline-block;
  background-color: $base-color;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include animation(pulse 2000ms linear infinite);
  &::before, &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 74px;
    background-color: $base-color;
    opacity: 0.15;
    z-index: -10; }
  &::before {
    z-index: -10;
    @include animation(inner-ripple 2000ms linear infinite); }
  &::after {
    z-index: -10;
    @include animation(outer-ripple 2000ms linear infinite); }
  i {
    font-size: 36px; }
  &.video-btn--sm {
    width: rem(70px);
    height: rem(70px); } }
@include keyframes(outer-ripple) {
  0% {
    @include transform(scale(1));
    opacity: 0.5; }
  80% {
    @include transform(scale(1.5));
    opacity: 0; }
  100% {
    @include transform(scale(2.5));
    opacity: 0; } }

@include keyframes(inner-ripple) {
  0% {
    @include transform(scale(1));
    opacity: 0.5; }
  30% {
    @include transform(scale(1));
    opacity: 0.5; }
  100% {
    @include transform(scale(1.5));
    opacity: 0; } }
.object-fit--cover {
  @include object-fit; }
.cursor {
  position: fixed;
  background-color: $base-color;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
  &.active {
    opacity: 1;
    transform: scale(0); }
  &.menu-active {
    opacity: 1;
    transform: scale(0); }
  &.hovered {
    opacity: 1; } }

.cursor-follower {
  position: fixed;
  border: 1px solid $base-color;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: translate(2px, 2px);
  &.active {
    opacity: 1;
    transform: scale(2); }
  &.menu-active {
    opacity: 1;
    transform: scale(2); }
  &.hovered {
    opacity: 1; } }

.overlay {
  z-index: 10;
  background-color: #222;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
.overlay-loader {
  width: 50px;
  height: 50px;
  background-color: #bada55; }


// PRELOADER
.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #141414;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 1s;
  transition-delay: 1s;
  .loadbar {
    width: 0%;
    height: 100%;
    max-height: 6px;
    background: $base-color;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden; }
  figure {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade 0.30s;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    position: relative;
    span {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      text-align: center;
      font-size: 14px; }
    img {
      height: 100px;
      display: inline-block;
      opacity: 0.5; } } }

@keyframes fade {
  0% {
    transform: translateY(-50px);
    opacity: 0; }

  100% {
    transform: translateY(0);
    opacity: 1; } }

// PAGE TRANSITION
.page-transition {
  width: 100%;
  height: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: $base-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }

// PAGE LOADED
.page-loaded {
  .preloader {
    height: 0;
    .loadbar {
      max-height: 100%;
      transition-duration: 1s;
      -webkit-transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
      -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
    figure {
      transform: translateY(50px);
      opacity: 0; } } }

// animation css
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }
@include keyframes (fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }
@include keyframes (fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }
@include keyframes (fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
/* global css end */
