/* roadmap section css start */
.roadmap-wrapper {
  position: relative;
  @include d-flex;
  flex-direction: column;
  padding-top: rem(30px);
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: $base-color;
    margin-left: -1px;
    @include media(767px) {
      display: none; } }
  .single-roadmap {
    position: relative;
    width: calc((100% / 2) - 60px);
    margin-bottom: rem(30px);
    align-self: flex-end;
    @include media(767px) {
      width: 100%; }
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: -17px;
      border-width: 10px 15px 10px 0px;
      border-style: solid;
      border-color: transparent rgba($base-color, 0.45) transparent transparent;
      margin-top: -10px;
      @extend %transition;
      @include media(767px) {
        display: none; } }
    &:hover {
      &::before {
        border-color: transparent rgba($base-color, 1) transparent transparent; } }
    &:nth-child(even) {
      align-self: flex-start;
      text-align: right;
      @include media(767px) {
        text-align: left; }
      .roadmap-dot {
        left: auto;
        right: -72px; }
      &:hover {
        &::before {
          border-color: transparent transparent transparent rgba($base-color, 1); } }
      &::before {
        left: auto;
        right: -17px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent rgba($base-color, 0.45); } } } }
.single-roadmap {
  position: relative;
  padding: rem(30px);
  @include border-radius(8px);
  @extend .glass--bg;
  border: 2px solid rgba($base-color, 0.45);
  @extend %transition;
  &:hover {
    border-color: $base-color;
    &::before {
      border-color: transparent rgba($base-color, 1) transparent transparent; } }
  .roadmap-dot {
    position: absolute;
    top: 50%;
    left: -72px;
    width: rem(20px);
    height: rem(20px);
    margin-top: -10px;
    background-color: $base-color;
    @include border-radius(50%);
    @include media(767px) {
      display: none; }
    &::before, &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 74px;
      background-color: $base-color;
      opacity: 0.35;
      z-index: -1; }
    &::before {
      z-index: -1;
      @include animation(inner-ripple 1000ms linear infinite); }
    &::after {
      z-index: -1;
      @include animation(outer-ripple 1000ms linear infinite); } }
  .title {
    margin-bottom: rem(15px); }
  .roadmap-date {
    font-size: rem(14px); } }
/* roadmap section css end */


/* feature section css start */
.feature-card {
  padding: rem(30px);
  @include border-radius(8px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0px 2px rgba($base-color, 0.45);
  @extend %transition;
  &:hover {
    box-shadow: 0 5px 25px 2px rgba($base-color, 0.25);
    @include transform(translateY(-5px)); }
  &::before {
    @include positionTwo;
    background-color: rgba($base-color-two, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    @include border-radius(8px);
    z-index: -1; }
  &__icon {
    margin-bottom: rem(25px);
    i {
      font-size: rem(48px);
      color: $base-color; } } }
/* feature section css end */

/* team section css start */
.team-card {
  padding: rem(15px);
  @include border-radius(10px);
  background-color: darken($base-color-two, 3%);
  box-shadow: 0 0 5px 1px rgba($base-color, 0.75);
  @extend %transition;
  position: relative;
  z-index: 1;
  &::after {
    @include positionTwo;
    background-color: rgba(#ffffff, 0.08);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    @include border-radius(10px);
    z-index: -1; }
  &:hover {
    box-shadow: 0 10px 25px 1px rgba($base-color, 0.15);
    @include transform(translateY(-5px)); }
  &__thumb {
    max-height: 330px;
    overflow: hidden;
    @include border-radius(5px);
    overflow: hidden; }
  &__content {
    padding: rem(30px) rem(15px) rem(15px) rem(15px);
    .name {
      font-size: 28px; } } }
/* team section css end */


/* testimonial section css start */
.testimonial-slider {
  .slick-list {
    margin: rem(-30px); }
  .single-slide {
    margin: rem(30px); }
  .prev, .next {
    position: absolute;
    top: 50%;
    width: rem(45px);
    height: rem(45px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(-22px);
    font-size: rem(22px);
    @extend .glass--bg;
    @include border-radius(5px);
    border: 2px solid rgba($base-color, 0.35);
    z-index: 1;
    @include media(575px) {
      display: none !important; } }
  .prev {
    left: rem(-20px); }
  .next {
    right: rem(-20px); } }
.testimonial-card {
  padding: rem(50px) rem(50px) rem(30px) rem(50px);
  border: 2px solid rgba($base-color, 0.35);
  @include border-radius(8px);
  @extend .glass--bg-two;
  text-align: center;
  position: relative;
  @include media(575px) {
    padding: rem(30px); }
  &::before {
    position: absolute;
    top: rem(-64px);
    left: rem(19px);
    content: "\f10d";
    font-family: "Line Awesome Free";
    font-weight: 900;
    color: $base-color;
    font-size: rem(80px);
    @include media(575px) {
      top: rem(-55px);
      font-size: rem(66px); } }
  &__content {
    p {
      font-size: rem(18px);
      color: #fff;
      font-style: italic;
      @include media(575px) {
        font-size: rem(16px); } } }
  &__client {
    margin-top: rem(30px);
    .thumb {
      width: rem(90px);
      height: rem(90px);
      display: inline-flex;
      @include border-radius(50%);
      overflow: hidden;
      border: 3px solid rgba(#ffffff, 0.25);
      img {
        width: inherit;
        height: inherit;
        object-position: top;
        -o-object-position: top; } }
    .designation {
      font-size: rem(14px); } } }
/* testimonial section css end */


/* subscribe section css start */
.subscribe-section {
  padding: rem(80px) 0; }
.subscribe-wrapper {
  padding: rem(50px);
  border: 2px solid rgba($base-color, 0.15);
  @include border-radius(8px);
  @extend .glass--bg-two;
  @include media(575px) {
    padding: rem(30px); }
  .title {
    font-size: rem(48px);
    @include media(767px) {
      font-size: rem(42px); }
    @include media(575px) {
      font-size: rem(32px); } }
  .details {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(20px); } }
.subscribe-form {
  background-color: $base-color-two;
  @include border-radius(999px);
  @include d-flex;
  align-items: center;
  padding: rem(10px);
  border: 2px solid rgba($base-color, 0.5);
  @include media(575px) {
    background-color: transparent;
    padding: 0;
    border: none;
    justify-content: center; }
  $btn-width: rem(196px);
  .form-control {
    background-color: $base-color-two;
    border: none;
    width: auto;
    color: #ffffff;
    width: calc(100% - #{$btn-width});
    &:focus {
      box-shadow: none; }
    @include media(575px) {
      width: 100%;
      margin-bottom: rem(20px);
      border: 1px solid rgba($base-color, 0.5);
      @include border-radius(999px); } }
  .subscribe-btn {
    padding: rem(14px) rem(30px);
    background-color: $base-color;
    color: #000;
    @include border-radius(999px);
    width: rem(196px); } }
/* subscribe section css end */


/* account-section css start */
.account-section {
  padding: rem(100px) 0;
  @include d-flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; }
.account-area {
  width: 100%; }
.account-wrapper {
  padding: rem(0) rem(50px) rem(50px) rem(50px);
  border: 2px solid rgba($base-color, 0.35);
  @include border-radius(8px);
  @extend .glass--bg-two;
  margin-top: rem(50px);
  @include media(440px) {
    padding: rem(0) rem(30px) rem(30px) rem(30px); } }
.account-thumb-area {
  margin-bottom: rem(40px);
  .title {
    font-size: rem(28px);
    margin-top: rem(20px);
    @include media(440px) {
      font-size: rem(24px); } }
  .account-thumb {
    width: rem(100px);
    height: rem(100px);
    border: 1px solid $base-color;
    @include border-radius(50%);
    color: $base-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(56px);
    background-color: $base-color-two;
    box-shadow: 0 3px 10px 2px rgba($base-color, 0.35);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(-50px); } }
/* account-section css end */


/* contact-form css start */
.contact-form {
  padding: rem(30px);
  @extend .glass--bg; }
.contact-card {
  height: 100%;
  padding: rem(30px);
  @include d-flex;
  align-items: center;
  border: 2px solid rgba($base-color, 0.15);
  @extend .glass--bg;
  @extend %transition;
  @include media(991px) {
    padding: rem(20px);
    justify-content: center; }
  &:hover {
    border-color: $base-color;
    box-shadow: 0 5px 10px 2px rgba($base-color, 0.25); }
  &__icon {
    width: rem(65px);
    i {
      font-size: rem(56px); } }
  &__content {
    width: calc(100% - #{rem(65px)});
    padding-left: rem(15px);
    @include media(991px) {
      width: 100%;
      padding-left: 0;
      text-align: center;
      margin-top: rem(20px); }
    .caption {
      margin-top: 6px;

      word-break: break-word;
      a {
        color: $para-color;
        &:hover {
          color: $base-color; } } } } }
/* contact-form css end */
