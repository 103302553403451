/* form css start */
.form-group {
  margin-bottom: rem(20px); }
.form-control {
  padding: rem(10px) rem(20px);
  border: 1px solid $base-color;
  width: 100%;
  background-color: $base-color-two;
  @include placeholder(rgba(#ffffff, 0.75));
  @include border-radius(3px);
  color: #ffffff;
  min-height: rem(55px);
  &:focus {
    background-color: $base-color-two;
    border-color: $base-color !important;
    box-shadow: 0 0 5px rgba($base-color, 0.35);
    color: #ffffff; }
  &:placeholder-shown {
    border-color: $border-color; }
  &.style--two {
    background-color: $base-color-two;
    &:placeholder-shown {
      border-color: rgba(#ffffff, 0.25);
      background-color: transparent; } }
  &[readonly] {
    background-color: $base-color-two; } }
.select {
  padding: rem(10px) rem(20px);
  width: 100%;
  border: 1px solid $border-color;
  cursor: pointer;
  color: $para-color;
  background-color: $base-color-two;
  height: rem(50px);
  @include border-radius(4px);
  option {
    padding: rem(10px) 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  &.style--two {
    background-color: transparent;
    border: 1px solid rgba(#ffffff, 0.15);
    font-size: rem(13px);
    padding: rem(5px) rem(10px);
    height: auto; }
  &.style--white {
    color: #ffffff;
    option {
      color: #000000; } } }
textarea {
  min-height: rem(150px) !important;
  resize: none;
  width: 100%; }
label {
  color: #ffffff;
  margin-bottom: rem(10px);
  font-family: $heading-font;
  font-size: rem(14px);
  font-weight: 600; }
.input-group {
   .form-control {} }
.input-group-text {
  background-color: $base-color;
  border-color: $base-color;
  color: #ffffff; }
/* form css end*/
