
$heading-color: #ffffff;
$para-color: #e8e8e8;

// $base-color: #4582ff
// $base-color: #1FA8F8
$base-color: #37ebec;
// $base-color-two: #042653
$base-color-two: #002046;

// bg-color
$bg-one: #001d4a;
$bg-two: #02014a;

// border-color
$border-color: rgba(#ffffff, 0.15);

// color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #10163A;
$light: #e8e8e8;
$muted: #cccccc;
