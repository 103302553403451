/* button css start */
button {
  &:focus {
    outline: none; } }

// BS defualt btn style
[class*="btn--"]:not(.btn--link, .btn--light, .btn) {
  color: #000; }
.btn--primary {
  background-color: $primary;
  &:hover {
    background-color: darken($primary, 5%); } }
.btn--secondary {
  background-color: $secondary;
  &:hover {
    background-color: darken($secondary, 5%); } }
.btn--success {
  background-color: $success;
  &:hover {
    background-color: darken($success, 5%); } }
.btn--danger {
  background-color: $danger;
  &:hover {
    background-color: darken($danger, 5%); } }
.btn--warning {
  background-color: $warning;
  &:hover {
    background-color: darken($warning, 5%); } }
.btn--info {
  background-color: $info;
  &:hover {
    background-color: darken($info, 5%); } }
.btn--light {
  background-color: $light;
  &:hover {
    background-color: darken($light, 5%); } }
.btn--dark {
  background-color: $dark;
  color: #fff;
  &:hover {
    background-color: darken($dark, 5%);
    color: #fff; } }
.btn--link {
  color: $primary; }
.btn--base {
  padding: rem(12px) rem(30px);
  background-color: $base-color;
  color: #000;
  &:hover {
    background-color: darken($base-color, 5%);
    color: #000; } }

// outline btn
.btn-outline--primary {
  color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $primary;
    color: #ffffff; } }
.btn-outline--secondary {
  color: $secondary;
  border-color: $secondary;
  &:hover {
    background-color: $secondary;
    color: #ffffff; } }
.btn-outline--success {
  color: $success;
  border-color: $success;
  &:hover {
    background-color: $success;
    color: #ffffff; } }
.btn-outline--danger {
  color: $danger;
  border-color: $danger;
  &:hover {
    background-color: $danger;
    color: #ffffff; } }
.btn-outline--warning {
  color: $warning;
  border-color: $warning;
  &:hover {
    background-color: $warning;
    color: #ffffff; } }
.btn-outline--info {
  color: $info;
  border-color: $info;
  &:hover {
    background-color: $info;
    color: #ffffff; } }
.btn-outline--light {
  color: $light;
  border-color: $light;
  &:hover {
    background-color: $light;
    color: #ffffff; } }
.btn-outline--dark {
  color: $dark;
  border-color: $dark;
  &:hover {
    background-color: $dark;
    color: #ffffff; } }
.btn-outline--base {
  color: $base-color;
  border: 1px solid $base-color;
  &:hover {
    background-color: $base-color;
    color: #000000; } }

// btn shadow
.btn-shadow--primary {
  box-shadow: 0 0 6px 1px rgba($primary, 0.35); }
.btn-shadow--secondary {
  box-shadow: 0 0 6px 1px rgba($secondary, 0.35); }
.btn-shadow--success {
  box-shadow: 0 0 6px 1px rgba($success, 0.35); }
.btn-shadow--danger {
  box-shadow: 0 0 6px 1px rgba($danger, 0.35); }
.btn-shadow--warning {
  box-shadow: 0 0 6px 1px rgba($warning, 0.35); }
.btn-shadow--info {
  box-shadow: 0 0 6px 1px rgba($info, 0.35); }
.btn-shadow--light {
  box-shadow: 0 0 6px 1px rgba($light, 0.35); }
.btn-shadow--dark {
  box-shadow: 0 0 6px 1px rgba($dark, 0.35); }
.btn-shadow--base {
  box-shadow: 0 0 6px 1px rgba($base-color, 0.35); }

.btn--group {
  @include d-flex;
  align-items: center;
  margin-left: rem(-10px);
  margin-right: rem(-10px);
  *[class*="btn"] {
    margin: rem(5px) rem(10px);
    align-items: center;
    &.d-flex {
      padding: rem(8px) rem(35px); } } }
[class*="btn"] {
  &.btn-md {
    padding: rem(10px) rem(20px); }
  &.btn-sm {
    padding: rem(6px) rem(10px); } }
/* button css end */
