/* table css start */
.custom--table {
  background-color: rgba($base-color, 0.15);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  @include border-radius(8px);
  &.white-space-nowrap {
    th {
      white-space: nowrap; } }
  thead {
    background-color: rgba($base-color-two, 0.75);
    th {
      border-bottom: 1px solid rgba(#ffffff, 0.15) !important;
      border-top: none;
      padding: rem(20px) rem(30px);
      color: #ffffff;
      font-size: rem(14px);
      text-transform: uppercase;
      text-align: center;
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } } }
  tbody {
    td {
      border-top: none;
      border-bottom: 1px solid rgba(#ffffff, 0.15);
      padding: rem(12px) rem(30px);
      color: #ffffff;
      text-align: center;
      vertical-align: middle;
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } }
    tr {
      @include transition(all 0.3s);
      &:nth-child(even) {
        background-color: rgba($base-color-two, 0.25); }
      &:last-child {
        td {
          border-bottom: none; } } } } }
// table responsive
[data-label] {
  position: relative;
  &::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #ffffff;
    top: 0;
    left: 0;
    padding: rem(13px) rem(15px);
    display: none;
    font-size: rem(12px); } }
.table-responsive--md {
  @media (max-width: 991px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #10101047; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#ffffff, 0.25) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--sm {
  @media (max-width: 767px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #10101047; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#ffffff, 0.25) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
*[class*="table-responsive--"] {
  &.data-label--none {
    @media (max-width: 1199px) {
      tr {
        th, td {
          padding-left: .75rem; } } } } }
/* table css end */
