/* hero css start */
.hero {
  padding-top: rem(300px);
  padding-bottom: rem(200px);
  position: relative;
  z-index: 1;
  @include media(1199px) {
    padding-top: rem(220px);
    padding-bottom: rem(100px); }
  &::after {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.55;
    z-index: -1; }
  &__title {
    font-size: rem(66px);
    @include media(1399px) {
      font-size: rem(56px); }
    @include media(1199px) {
      font-size: rem(48px); }
    @include media(575px) {
      font-size: rem(36px); } } }
.count-wrapper {
  padding: rem(50px) rem(30px); }
.date-unit-list {
  .single-unit {
    width: calc((100% / 4) - 15px);
    padding: rem(20px) rem(10px);
    @include border-radius(5px);
    background-color: rgba($base-color-two, 0.75);
    @include media(1199px) {
      padding: rem(10px); }
    @include media(540px) {
      width: calc((100% / 2) - 10px);
      margin-bottom: 20px; }
    span {
      display: block;
      font-size: rem(32px);
      font-weight: 700;
      color: $base-color;
      @include media(360px) {
        font-size: rem(24px); } } } }
/* hero css end */


/* inner-hero css start */
.inner-hero {
  padding-top: rem(220px);
  padding-bottom: rem(120px);
  position: relative;
  z-index: 1;
  @include media(1199px) {
    padding-top: rem(190px);
    padding-bottom: rem(85px); }
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.5;
    z-index: -1; } }
.page-title {
  font-size: rem(42px);
  @include media(1199px) {
    font-size: rem(36px); }
  @include media(767px) {
    font-size: rem(32px); } }
/* inner-hero css end */
