/* footer section css start */
.footer {
  &__top {
    padding-top: rem(100px);
    padding-bottom: rem(100px); }
  .footer-logo {
    img {
      max-height: rem(50px); } }
  .footer-menu {
    margin: rem(-5px) rem(-15px);
    li {
      margin: rem(5px) rem(15px);
      a {
        color: #ffffff;
        &:hover {
          color: $base-color; } } } }
  &__bottom {
    padding: rem(15px) 0;
    background-color: darken($base-color-two, 3%); } }
.social-links {
  margin: rem(-5px) rem(-7px);
  li {
    margin: rem(5px) rem(7px);
    a {
      width: rem(35px);
      height: rem(35px);
      border: 1px solid rgba(#ffffff, 0.15);
      color: #ffffff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include border-radius(50%);
      @extend  %transition;
      &:hover {
        background-color: $base-color;
        color: #ffffff;
        border-color: $base-color; } } } }
/* footer section css end */
