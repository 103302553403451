/* header start */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  &.menu-fixed {
    .header__top {
      display: none; }
    .header__bottom {
      background-color: $base-color-two; } }
  &__top {
    background-color: $base-color-two;
    padding: rem(10px) 0; }
  .top-info {
    a {
      color: $base-color; } }
  &__bottom {
    background-color: rgba(#ffffff, 0.03);
    border-bottom: 1px solid rgba(#ffffff, 0.15);
    @extend  %transition;
    @include media(1199px) {
      padding: rem(10px) 0; } }
  .site-logo {
    img {
      width: rem(175px);
      @include media(1199px) {
        max-width: rem(150px); } } }
  .main-menu {
    margin-left: rem(70px);
    @include media(1199px) {
      margin-left: 0;
      padding: rem(15px) 0; }
    li {
      position: relative;
      @include media(1199px) {
        border-bottom: 1px solid rgba(#ffffff, 0.15); }
      &:last-child {
        a {
          padding-right: 0; } }
      &.menu_has_children {
        position: relative;
        &.open {
          .sub-menu {
            display: block; } }
        >a {
          padding-right: rem(25px);
          @include media(1199px) {
            display: block; }
          &::before {
            position: absolute;
            content: "\f067";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: rem(25px);
            right: 0;
            color: #ffffff;
            @extend  %transition;
            @include media(1199px) {
              display: block;
              top: rem(9px); } } }
        &:hover {
          >a::before {
            content: "\f068";
            color: $base-color; } } }
      a {
        padding: rem(25px) rem(15px) rem(25px) 0;
        text-transform: capitalize;
        font-size: rem(16px);
        color: #ffffff;
        position: relative;
        font-weight: 400;
        @include media(1199px) {
          color: #ffffff;
          padding: rem(8px) 0;
          display: block; }
        &:hover,&:focus {
          color: $base-color; } }
      .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        z-index: 9999;
        background-color: $base-color;
        padding: rem(10px) 0;
        -webkit-box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
        box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
        @include border-radius(5px);
        @extend  %transition;
        opacity: 0;
        visibility: hidden;
        border: 2px solid $base-color;
        // &::before
        //   position: absolute
        //   content: ''
        //   top: -9px
        //   right: rem(28px)
        //   width: rem(15px)
        //   height: rem(15px)
        //   background-color: $base-color
        //   +border-radius(3px)
        //   +transform(rotate(45deg))
        //   border-top: 3px solid $base-color
        //   border-left: 3px solid $base-color
        //   +media(1199px)
        //     display: none
        @include media(1199px) {
          opacity: 1;
          visibility: visible;
          display: none;
          position: static;
          @include transition(none);
          width: 100%; }
        li {
          border-bottom: 1px solid rgba(#000000, 0.05);
          &:last-child {
            border-bottom: none; }
          a {
            padding: rem(8px) rem(25px);
            display: block;
            color: #000000;
            position: relative;
            @extend  %transition;
            &::before {
              position: absolute;
              content: '';
              top: 0;
              left: rem(-4px);
              width: rem(4px);
              height: 100%;
              background-color: $base-color;
              opacity: 0;
              @extend  %transition; }
            &:hover {
              background-color: rgba($base-color, 0.05);
              color: #000000;
              &::before {
                opacity: 1;
                left: 0; } } } }
        li+li {
          margin-left: 0; } }
      &:hover .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; } }
    li+li {
      margin-left: rem(20px);
      @include media(1199px) {
        margin-left: 0; } } }
  .nav-right {
    padding-left: rem(50px);
    @include d-flex;
    align-items: center;
    @include media(1199px) {
      padding-left: 0; }
    .select {
      background-color: transparent;
      width: auto;
      padding: 0;
      height: auto;
      color: #ffffff;
      border: none;
      option {
        color: #ffffff;
        background-color: $base-color-two; } } } }
.header-login-btn {
  color: $base-color-two;
  padding: rem(8px) rem(20px);
  border: 1px solid $base-color;
  background-color: rgba($base-color, 1);
  @include border-radius(5px);
  font-size: rem(14px);
  display: inline-flex;
  align-items: center;
  line-height: 1;
  @extend  %transition;
  &:hover {
    background-color: rgba($base-color, 0.35);
    box-shadow: 0 0 5px 2px rgba($base-color, 0.5);
    i {
      color: $base-color; } }
  i {
    color: $base-color-two;
    font-size: rem(24px);
    margin-right: rem(6px);
    @extend  %transition; } }
.navbar-collapse {
  @include media(1199px) {
    background-color: $bg-one;
    padding: 0 rem(30px) rem(20px) rem(30px); }
  @include media(767px) {
    max-height: rem(320px);
    overflow: auto; } }
.navbar {
  @include media(1199px) {
    justify-content: start;
    .site-logo {
      margin: 0 auto; } } }
.navbar-toggler {
  padding: 0;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none; } }
.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: rem(35px);
  height: rem(20px);
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #ffffff;
  font-size: 0;
  @include transition(all 0.25s ease-in-out);
  cursor: pointer; }
.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  @include transform(translate(-50%, -50%));
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out;
  @include media(1199px) {
    background-color: #ffffff; } }
.navbar-toggler[aria-expanded="true"] {
  span {
    border-color: transparent; }
  span:before {
    @include transform(translate(-50%, -50%) rotate(45deg)); }
  span:after {
    @include transform(translate(-50%, -50%) rotate(-45deg)); }
  span.menu-toggle:hover {
    color: #ffffff;
    @include media(1199px) {
      color: #ffffff; } }
  span {
    border-color: transparent; }
  span:before {
    @include transform(translate(-50%, -50%) rotate(45deg)); }
  span:after {
    @include transform(translate(-50%, -50%) rotate(-45deg)); } }

// create animation for when menu is fixed
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }
@include keyframes (fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
/* header end */
